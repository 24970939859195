@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700&display=swap");
input:focus,
select:focus,
textarea:focus {
  outline: 0 !important;
}

body {
  font-family: FF;
  font-family: "Cairo", sans-serif;
  direction: rtl;
  margin: 0;
  box-sizing: border-box !important;
}

div,
a,
p,
span,
h6 h5,
h4,
h3,
h2,
h1 {
  box-sizing: border-box;
}

.clear {
  clear: both;
}

img {
  max-width: 100%;
}

a,
button {
  text-decoration: none !important;
  outline: none;
  transition: all 0.3s;
}

/* side nav */
.openMenuActive {
  height: 100vh;
  overflow: hidden;
}

/* Loading */
.section-loading {
  top: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  z-index: 999999;
  align-items: center;
  justify-content: center;
  background-color: #01348f;
  transition: all 0.3s ease-in-out;
  right: 0;
}
.section-loading ul.list-bars {
  list-style: none;
  display: flex;
  align-items: center;
}
.section-loading ul.list-bars li {
  margin: 0 7px;
  width: 8px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-animation: animate 0.8s infinite alternate;
          animation: animate 0.8s infinite alternate;
}
.section-loading ul.list-bars li:nth-child(odd) {
  height: 80px;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.section-loading ul.list-bars li:nth-child(even) {
  height: 20px;
}

.form_page {
  opacity: 0.4;
}

.loading_none {
  opacity: 0 !important;
  visibility: hidden !important;
  z-index: -1 !important;
}

@-webkit-keyframes animate {
  from {
    height: 5px;
  }
  to {
    height: 70px;
  }
}

@keyframes animate {
  from {
    height: 5px;
  }
  to {
    height: 70px;
  }
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  text-align: right;
  font-family: "Cairo", sans-serif !important;
}

.Toastify__toast--rtl {
  font-family: "Cairo", sans-serif !important;
}

.Toastify__toast-container .Toastify__toast-container--top-right .Toastify__toast-container--rtl {
  font-family: "Cairo", sans-serif !important;
}

.bg-sidenavOpen {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  cursor: pointer;
  display: none;
}

.Mysidebar {
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
  width: 230px;
  position: fixed;
  top: 0;
  right: -300px;
  overflow: auto;
  padding: 10px 20px;
  z-index: 999;
  transition: all 0.3s ease-in-out;
}
.Mysidebar .closeSideMenue {
  position: absolute;
  left: 10px;
  width: 35px;
  height: 35px;
  background-color: transparent;
  color: #01348f;
  border: 2px solid #01348f;
  border-radius: 50%;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.Mysidebar .list-sidebar {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.Mysidebar .list-sidebar .navItem {
  margin-bottom: 10px;
}
.Mysidebar .list-sidebar .navItem .navLink {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  border-bottom: 1px solid rgb(243, 243, 243);
  display: block;
  padding: 8px 0;
}
.Mysidebar .list-sidebar .navItem .navLink:hover {
  color: #01348f;
}
.Mysidebar .list-sidebar .navItem .submenue {
  padding: 0;
  padding-right: 20px;
  margin: 0;
}
.Mysidebar .list-sidebar .navItem .submenue .navItem {
  margin-bottom: 0;
  margin-top: 10px;
}
.Mysidebar .list-sidebar .navItem .submenue .navItem::marker {
  content: "\f104";
  color: #fa6005;
}
.Mysidebar .list-sidebar .navItem .submenue .navItem:hover::marker {
  color: #01348f;
}
.Mysidebar .list-sidebar .navItem .submenue .navItem:hover .navLink {
  color: #01348f;
}
.Mysidebar .list-sidebar .navItem .submenue .navLink {
  font-size: 16px;
  color: #000;
}
.Mysidebar .list-sidebar .navItem:last-child .navLink {
  border-bottom: 0;
}
.Mysidebar .logo {
  text-align: center;
  padding-top: 40px;
  margin-bottom: 40px;
}
.Mysidebar .logo img {
  width: 80px;
}
.Mysidebar .login {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Mysidebar .login .navLink {
  font-size: 16px;
  background-color: #01348f;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  padding: 10px;
  margin-top: 10px;
}
.Mysidebar .login .navLink:hover {
  background-color: #179c46;
}
.Mysidebar .login .navLink:first-child {
  background-color: #f1f1f1;
  color: #000;
}
.Mysidebar .login .navLink:first-child:hover {
  background-color: #dfdfdf;
}

/* mynavbr */
.mynavbr {
  z-index: 99;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  top: 0;
  width: 100%;
}
.mynavbr .mynavbar-parent {
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-top: 10px;
  padding: 5px 15px;
  border-radius: 10px;
}
.mynavbr .mynavbar-parent .logo {
  width: 40px;
}
.mynavbr .mynavbar-parent ul {
  display: flex;
  margin-bottom: 0;
  margin-top: 0;
  list-style-type: none;
  padding: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box !important;
}
.mynavbr .mynavbar-parent ul .navItem {
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
  box-sizing: border-box !important;
}
.mynavbr .mynavbar-parent ul .navItem .navLink {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box !important;
  display: flex;
  align-items: center;
}
.mynavbr .mynavbar-parent ul .navItem .navLink i {
  margin-top: 4px;
  margin-left: 5px;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.mynavbr .mynavbar-parent ul .navItem:hover a {
  color: #01348f;
}
.mynavbr .mynavbar-parent ul .navItem:hover a i {
  color: #01348f;
}
.mynavbr .mynavbar-parent ul .navItem:hover::after {
  width: 50%;
}
.mynavbr .mynavbar-parent ul .navItem:hover .submenue {
  opacity: 1 !important;
  visibility: visible !important;
  transform: translateY(3px) !important;
}
.mynavbr .mynavbar-parent .login {
  display: flex;
}
.mynavbr .mynavbar-parent .login .navLink {
  font-size: 16px;
  background-color: #01348f;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  padding: 10px;
  margin-right: 10px;
}
.mynavbr .mynavbar-parent .login .navLink i {
  margin-left: 5px;
  margin-top: 3px;
}
.mynavbr .mynavbar-parent .login .navLink:hover {
  background-color: #012f7e;
}
.mynavbr .mynavbar-parent .login .navLink:first-child {
  background-color: #f1f1f1;
  color: #000;
}
.mynavbr .mynavbar-parent .login .navLink:first-child:hover {
  background-color: #dfdfdf;
}
.mynavbr .mynavbar-parent .login .dropdown button {
  font-size: 16px;
  padding: 10px;
  margin-right: 10px;
  background-color: #012f7e;
  border-color: #012f7e;
}
.mynavbr .mynavbar-parent .login .dropdown button:focus {
  outline: 0 !important;
  box-shadow: none;
}
.mynavbr .mynavbar-parent .login .dropdown .btn_logout {
  background-color: transparent;
  border-color: transparent;
  margin: 0;
  color: #eb0303;
  width: 100%;
  padding: 0;
  text-align: right;
  margin-right: 10px;
}
.mynavbr .mynavbar-parent .login .dropdown .btn_logout i {
  margin-left: 10px;
}
.mynavbr .mynavbar-parent .login .dropdown a {
  color: #000;
  padding: 7px 10px !important;
  display: block;
  font-size: 0.9rem;
}
.mynavbr .mynavbar-parent .login .dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.mynavbr .mynavbar-parent .login .dropdown a i {
  font-size: 0.9rem;
  margin-left: 10px;
}
.mynavbr .sidemenue-icon {
  display: none;
}
.mynavbr .sidemenue-icon i {
  font-size: 25px;
  color: #01348f;
}

/* header */
.header {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.header .header_img_video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header .header_img_video video, .header .header_img_video img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.header .bg-after {
  position: absolute;
  z-index: 1;
  bottom: -50px;
  left: 0;
  right: 0;
  /* width: 100%; */
  height: 100%;
  background-position: center;
  /* transform: rotate(180deg); */
  background-size: cover;
}
.header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.26);
}
.header .header-content h5 {
  font-size: 50px;
  color: #fff;
  font-weight: 800;
  position: relative;
  z-index: 9;
  margin: 0;
}
.header .header-content p {
  font-size: 20px;
  color: #fff;
  position: relative;
  z-index: 9;
  margin: 0;
}

.about_us_page h5 {
  font-size: 2rem;
  font-weight: bold;
  margin: 30px 0;
}
.about_us_page p {
  line-height: 30px;
}

.overview {
  padding-top: 97px;
}
.overview .container {
  position: relative;
}
.overview .overview-img {
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 200px;
  filter: grayscale(1);
  opacity: 0.2;
}
.overview .over-content-1 h5 {
  font-size: 16px;
  color: #fa6005;
  margin: 0;
  margin-bottom: 0;
  font-weight: 600;
}
.overview .over-content-1 p {
  color: #002543;
  font-size: 27px;
  font-weight: bold;
}
.overview .over-content-2 {
  margin-top: 20px;
}
.overview .over-content-2 p {
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 30px;
  max-width: 100%;
}

/* statics */
.statics {
  margin-top: 90px;
}

/* state-card */
.state-card {
  border-radius: 15px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: all 0.5s ease;
  text-align: center;
  padding: 30px 5px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
}
.state-card .card-img {
  border-radius: 24px;
  padding: 15px;
  width: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.state-card .card-img-1 {
  background-image: linear-gradient(135deg, #01348f 10%, #478ecc);
}
.state-card .card-img-2 {
  background-image: linear-gradient(135deg, #fa6005 10%, #fa6005);
}
.state-card .card-img-3 {
  background-image: linear-gradient(135deg, #478ecc 10%, #01348f);
}
.state-card .card-img-4 {
  background-image: linear-gradient(135deg, #fa6005 10%, #fa6005);
}
.state-card .card-number {
  font-size: 40px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}
.state-card .card-title {
  font-size: 16px;
  font-weight: 800;
  margin: 0;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
}
.state-card .card-text {
  margin: 0;
  font-size: 15px;
  margin-top: 9px;
  transition: all 0.3s ease-in-out;
}
.state-card:hover {
  transform: translateY(-8px);
  background-color: #01348f;
}
.state-card:hover .card-number,
.state-card:hover .card-text,
.state-card:hover .card-title {
  color: #fff;
}

/* cources */
.section-header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section-header h5 {
  font-size: 40px;
  font-weight: bold;
  color: #01348f;
  margin: 0;
}
.section-header p {
  color: #444;
  font-size: 14px;
  margin: 0;
}
.section-header .bnt-more {
  text-align: center;
}
.section-header .bnt-more .more {
  padding: 10px 25px;
  text-align: center;
  color: #444;
  font-size: 17px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgb(214, 214, 214);
}
.section-header .bnt-more .more:hover {
  background-color: #ddd;
  color: #01348f;
  border-color: #ddd;
}

.cources {
  margin-top: 90px;
}

.course-card {
  background-color: #fff;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  margin-bottom: 20px;
}
.course-card .card-img {
  height: 300px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
}
.course-card .card-img .img_parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.course-card .card-img .img_parent img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.course-card .card-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.course-card .card-instructor {
  display: flex;
  align-items: center;
}
.course-card .card-instructor .card-img-instructio {
  width: 26px;
  height: 37px;
  border-radius: 50%;
  margin-left: 5px;
  background-size: contain !important;
  background-repeat: no-repeat;
}
.course-card .card-instructor span {
  font-size: 15px;
  font-weight: 500;
  color: #01348f;
}
.course-card .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
  color: #000;
}
.course-card .card-rate {
  display: flex;
  align-items: center;
}
.course-card .card-rate i {
  margin-left: 5px;
  color: #ffc600;
}
.course-card .card-rate span {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #478ecc;
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  padding: 3px 5px;
}
.course-card .card-duration {
  font-size: 15px;
  color: #01348f;
  display: flex;
  align-items: center;
}
.course-card .card-duration i {
  margin-top: 5px;
  margin-left: 5px;
}
.course-card .card-price {
  font-size: 40px;
  font-weight: bold;
  color: #179c46;
  margin-top: 10px;
}
.course-card .card-price span {
  font-size: 15px;
}
.course-card .card-footer {
  padding: 0;
  border-top: 0;
}
.course-card .card-footer .visit-cource {
  background-color: #01348f;
  color: #fff;
  font-size: 17px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 5px;
  border-radius: 5px;
  margin-top: 10px;
}
.course-card:hover {
  transform: translateY(-10px);
}

/* testemonial */
.testemonial {
  margin-top: 90px;
  background-color: #ebebeb;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
}
.testemonial .section-header {
  margin-bottom: 10px;
}
.testemonial .slick-slide {
  padding: 10px;
}
.testemonial .slick-list {
  padding-top: 30px;
}
.testemonial .teste-bf {
  position: absolute;
  bottom: -40px;
  width: 100%;
}
.testemonial .slick-dots {
  display: flex !important;
  justify-content: center;
  margin: 0;
  list-style-type: none;
}
.testemonial .slick-dots li {
  margin: 0;
}
.testemonial .slick-dots button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  border: 2px solid #fa6005;
  text-indent: -9999px;
}
.testemonial .slick-dots li.slick-active button {
  background-color: #fa6005;
}
.testemonial .slick-arrow {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #fa6005;
  border-radius: 5px;
}
.testemonial .slick-arrow.slick-prev {
  z-index: 9;
  left: 0;
  top: 100%;
  margin-top: 20px;
}
.testemonial .slick-arrow.slick-prev::before {
  content: "\f104";
  opacity: 1;
}
.testemonial .slick-arrow.slick-next {
  right: 0;
  top: 100%;
  margin-top: 20px;
  z-index: 9;
}
.testemonial .slick-arrow.slick-next::before {
  content: "\f105";
  opacity: 1;
}
.testemonial .slick-prev.slick-disabled:before,
.testemonial .slick-next.slick-disabled:before {
  opacity: 0.35;
}
.testemonial .slick-prev:hover,
.testemonial .slick-prev:focus,
.testemonial .slick-next:hover,
.testemonial .slick-next:focus {
  background-color: #e05606;
}

/* teste card */
.teste-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  padding-bottom: 15px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 10px solid #fa6005;
}
.teste-card .card-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 4px solid #fff;
  background-size: 100% !important;
  background-repeat: no-repeat;
  position: relative;
  margin-top: -60px;
}
.teste-card .card-img .img_parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.teste-card .card-img .img_parent img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.teste-card .card-name,
.teste-card .card-text {
  margin: 0;
  text-align: center;
}
.teste-card .card-name {
  font-size: 17px;
  margin-top: 10px;
  font-weight: 600;
}
.teste-card .card-date {
  color: #666;
  font-size: 13px;
  display: inline-block;
}
.teste-card .card-text {
  font-size: 15px;
  margin-top: 10px;
}
.teste-card:hover {
  border-bottom-color: #01348f;
}

/*  */
.main-video-parent {
  margin-top: 120px;
}

.main-video {
  height: 400px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-video::after {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  top: 0;
  content: "";
  position: absolute;
  border-radius: 10px;
}
.main-video .btn-play {
  border-radius: 50%;
  border: 0;
  width: 130px;
  height: 130px;
  font-size: 50px;
  color: #fa6005;
  position: relative;
  z-index: 6;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* our-services */
.our-services {
  margin-top: 90px;
}

/* serv-card */
.serv-card {
  box-shadow: 6px 4px 18px rgba(0, 0, 0, 0.08);
  border: 1px solid #eee;
  background-color: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
}
.serv-card .serv-number {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 20px;
  left: 0;
  background-color: #01348f;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  padding: 5px 20px;
}
.serv-card .card-title {
  margin-top: 40px;
  font-size: 25px;
  font-weight: bold;
}
.serv-card .card-text {
  line-height: 30px;
  color: #333;
  font-size: 15px;
  margin-top: 15px;
}
.serv-card:hover {
  box-shadow: none;
}

/* contact */
/* contact */
.contact {
  margin-top: 100px;
}
.contact .section-contact {
  margin-bottom: 61px;
}
.contact .section-contact h6 {
  color: #ddd;
  font-size: 18px;
}
.contact .section-contact h5 {
  color: #000;
  font-size: 30px;
}
.contact .cont {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.contact .cont h5,
.contact .cont p {
  margin: 0;
}
.contact .cont .cont-img {
  margin-left: 10px;
}
.contact .cont h5 {
  font-size: 20px;
  color: #000;
  margin-top: 5px;
  margin-bottom: 5px;
}
.contact .cont p, .contact .cont a {
  font-size: 15px;
  color: #7f8384;
  margin: 0;
}
.contact .cont p:hover, .contact .cont a:hover {
  text-decoration: underline;
}
.contact .contact-img {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* login-img */
.login-page .row {
  margin: 0;
}
.login-page .row .col-md-6 {
  padding-left: 0;
  padding-right: 0;
}

.error_handle {
  color: red;
  font-size: 0.9rem;
}

.login-img {
  height: 100vh;
  background-color: #478ecc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.login-img h5 {
  font-size: 3em;
  color: #fff;
  font-weight: bold;
}
.login-img p {
  color: #fff;
  margin-top: 10px;
  line-height: 30px;
  width: 80%;
  max-width: 100%;
}
.login-img a {
  border: 2px solid #fff;
  color: #fff;
  width: 50%;
  padding: 10px;
}
.login-img a:hover {
  background-color: #fff;
  color: #01348f;
}

.login-content {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-content h5 {
  color: #01348f;
  font-weight: bold;
  font-size: 2.5em;
  text-align: center;
}
.login-content .loginForm {
  width: 70%;
  margin: auto;
  margin-top: 30px;
}
.login-content .form-control {
  height: 55px;
  background-color: #eee;
  border-color: #eee;
}
.login-content .form-control:focus {
  box-shadow: none;
  outline: 0;
  border: 1px solid #01348f;
}
.login-content .btnSignin {
  color: #fff;
  border-radius: 0;
  width: 200px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  background-color: #01348f;
  padding: 10px 10px;
  border-radius: 5px;
  margin-top: 15px;
}
.login-content .social {
  text-align: center;
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}
.login-content .social a {
  width: 35px;
  height: 35px;
  border: 1px solid #999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 5px;
}
.login-content .social a i {
  color: #999;
}

.rigister-page .loginForm {
  width: 90% !important;
}

/* Breadcrumb */
.breadcrumb {
  height: 500px;
  display: block;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.breadcrumb::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
}
.breadcrumb h5 {
  display: block !important;
  font-size: 39px;
  margin-bottom: 18px;
  font-weight: bold;
  color: #fff;
  position: relative;
  z-index: 9;
}

/* my account */
.my-account {
  margin-top: 40px;
  margin-bottom: 50px;
}
.my-account .log-out {
  margin-top: 10px;
}
.my-account .log-out .btn {
  background-color: rgb(235, 3, 3);
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px;
}
.my-account .log-out .btn i {
  margin-left: 5px;
  transform: rotate(180deg);
}
.my-account .log-out .btn:hover {
  background-color: rgb(211, 3, 3);
}
.my-account .nav {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
}
.my-account .nav-link {
  padding: 20px 10px;
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
}
.my-account .nav-link i {
  margin-top: 5px;
  margin-left: 5px;
}
.my-account .nav-link.active {
  background-color: #01348f;
}
.my-account .nav-link.active:hover {
  background-color: #01348f;
}
.my-account .nav-link:hover {
  background-color: #eee;
}
.my-account .nav-item:last-child .nav-link {
  border-bottom: 0;
}
.my-account .tabHed {
  background-color: #f1f1f1;
  padding: 20px 10px;
  border-radius: 5px;
}
.my-account .tabHed h5 {
  margin: 0;
  color: #000;
  font-size: 18px;
}
.my-account .tabContent {
  margin-top: 40px;
}
.my-account .tabContent form {
  overflow: hidden;
}
.my-account .tabContent .form-group .form-control {
  height: 55px;
  border-radius: 5px;
  border-color: #eee;
  font-size: 16px;
  color: #000;
  background-color: #fff;
}
.my-account .tabContent .form-group .form-control:focus {
  outline: 0;
  box-shadow: none;
  border-color: #01348f;
}
.my-account .tabContent .form-group input:disabled {
  background-color: #eee;
}
.my-account .tabContent .course-card .card-img {
  height: 200px;
}
.my-account .btn-submit button {
  width: 100%;
  background-color: #179c46;
  color: #fff;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
}
.my-account .btn-submit button:hover {
  background-color: #13853b;
}
.my-account .course_img {
  text-align: center;
}
.my-account .course_img .upload_title {
  background-color: #002543;
  color: #fff;
  padding: 15px 40px;
  display: inline-block;
}
.my-account .course_img label, .my-account .course_img input {
  cursor: pointer;
}
.my-account .course_img .card_img {
  cursor: pointer;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  margin: auto;
}
.my-account .course_img .card_img .img_parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.my-account .course_img .card_img .img_parent img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.my-account .course_img input[type=file] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

/* course-details */
.course-details {
  margin-bottom: 50px;
  /* comments */
}
.course-details .cours-det .course-img {
  margin-top: -60px;
  height: 400px;
  border-radius: 5px;
  position: relative;
}
.course-details .cours-det .course-img .img_parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.course-details .cours-det .course-img .img_parent img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.course-details .cours-det .intro {
  margin-top: 10px;
}
.course-details .cours-det .intro .btn {
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #01348f;
  flex-direction: row-reverse;
}
.course-details .cours-det .intro .btn i {
  margin-top: 5px;
  color: #fff;
  font-size: 18px;
}
.course-details .cours-det .intro .btn span {
  color: #fff;
  font-size: 18px;
}
.course-details .cours-det .intro .btn img {
  width: 25px;
}
.course-details .cours-det .intro .btn:hover {
  background-color: #002543;
}
.course-details .cours-det .buy-course .btn {
  background-color: #179c46;
}
.course-details .cours-det .buy-course .btn:hover {
  background-color: #0d7a33;
}
.course-details .cours-det .buy-course .btn img {
  width: 25px;
}
.course-details .cours-det .buy-course .paymentOption {
  display: flex;
  align-items: center;
}
.course-details .cours-det .buy-course .seprate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.course-details .cours-det .buy-course .seprate .line {
  width: 100%;
  height: 1px;
  background-color: rgb(201, 201, 201);
}
.course-details .cours-det .buy-course .seprate .world {
  padding: 0 5px;
  font-weight: bold;
}
.course-details .cours-det .buy-course .subPrice {
  margin: 0;
  margin-right: 10px;
}
.course-details .cours-det .buy-course .subPrice img {
  width: 25px;
}
.course-details .cours-det .latest-course {
  margin-top: 40px;
}
.course-details .cours-det .latest-course .latest-head {
  background-color: #f1f1f1;
  padding: 20px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.course-details .cours-det .latest-course .latest-head h5 {
  margin: 0;
  color: #000;
  font-size: 18px;
}
.course-details .cours-det .latest-course .course-card .card-img {
  height: 200px;
}
.course-details .course-info {
  background-color: #fff;
  box-shadow: 6px 4px 18px rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 5px;
}
.course-details .course-info .course-info-det {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #fa6005;
  margin-bottom: 20px;
}
.course-details .course-info ul {
  display: flex;
  flex-direction: column;
}
.course-details .course-info ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.course-details .course-info ul li .title {
  display: flex;
  align-items: center;
  color: #444;
}
.course-details .course-info ul li .title i {
  margin-top: 5px;
  margin-left: 5px;
}
.course-details .course-info ul li .desc {
  font-weight: 600;
  font-size: 17px;
  display: flex;
  align-items: center;
}
.course-details .course-info ul li .desc img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 5px;
}
.course-details .course-info ul li:last-child {
  margin-bottom: 0;
}
.course-details .coursr-descreption-content .nav {
  background-color: #01348f;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: space-evenly;
  border-radius: 10px;
  margin-top: 30px;
}
.course-details .coursr-descreption-content .nav .nav-link {
  background-color: transparent;
  color: #fff;
  font-size: 15px;
  border-radius: 0;
  border: 0;
  border-bottom: 0 solid transparent;
}
.course-details .coursr-descreption-content .nav .nav-link.active {
  color: #fff;
  position: relative;
  border: 0;
}
.course-details .coursr-descreption-content .nav .nav-link.active::after {
  content: "\f0d7";
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translate(-50%, 0);
  color: #01348f;
  font-size: 30px;
}
.course-details .coursr-descreption-content .description-title {
  font-size: 19px;
  margin: 0;
  margin-bottom: 20px;
  font-weight: bold;
  margin-top: 30px;
}
.course-details .coursr-descreption-content .description-desc {
  font-size: 14px;
  color: #444;
  line-height: 30px;
}
.course-details .coursr-descreption-content .description-desc ul {
  list-style-type: none;
  padding-right: 0;
}
.course-details .cours-content .card {
  padding: 15px 15px;
  box-shadow: 6px 4px 18px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  border: 0;
}
.course-details .cours-content .card .card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  font-size: 16px;
}
.course-details .cours-content .card .card-title .btn {
  background-color: #179c46;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.course-details .cours-content .card .card-title .btn i {
  margin-top: 7px;
  margin-right: 5px;
}
.course-details .cours-content .card .card-title .btn:hover {
  background-color: #128b3d;
}
.course-details .comments {
  margin-top: 70px;
}
.course-details .comments .comment-head {
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-details .comments .comment-head span {
  background-color: #eee;
  width: 100%;
  height: 1px;
  display: inline-block;
  margin-top: 5px;
}
.course-details .comments .comment-head h5 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}
.course-details .comment-card {
  border: 1px solid #eee;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  position: relative;
  border-radius: 5px;
}
.course-details .comment-card .card-date {
  position: absolute;
  top: 10px;
  left: 10px;
}
.course-details .comment-card .card-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.course-details .comment-card .card-text {
  color: #444;
  margin: 0;
  font-size: 14px;
  margin-top: 10px;
  line-height: 25px;
}
.course-details .comment-card .name_img {
  display: flex;
  align-items: center;
}
.course-details .comment-card .card_img {
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;
  margin-left: 10px;
}
.course-details .comment-card .card_img .img_parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.course-details .comment-card .card_img .img_parent img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.course-details .add-comment {
  margin-top: 50px;
}
.course-details .add-comment .comment-head {
  justify-content: space-between !important;
  margin-bottom: 20px;
}
.course-details .add-comment .comment-head span {
  background-color: #eee;
  width: 200px;
}
.course-details .add-comment .form-control {
  height: 55px;
}
.course-details .add-comment .form-control:focus {
  outline-style: 0;
  box-shadow: none;
}
.course-details .add-comment textarea {
  height: 150px !important;
  resize: none;
}
.course-details .add-comment .btn {
  background-color: #01348f;
  color: #fff;
  font-size: 17px;
}
.course-details .noLogin_title {
  font-size: 1rem;
  text-align: center;
  margin-top: 15px;
  border: 1px solid #eee;
  padding: 15px;
}

/* courses_page */
.courses_page .section-header {
  padding: 20px 0;
}

/* certificate_checker */
.certificate_checker {
  padding: 100px 0;
}
.certificate_checker .login-content {
  height: auto !important;
}

/* certificate_details_page */
.certificate_details_page {
  padding: 100px 0;
}
.certificate_details_page .head_page {
  color: #01348f;
  font-weight: bold;
  text-align: center;
  font-size: 2rem;
}
.certificate_details_page .info_cards {
  margin: 50px 0;
}

.enroll_error {
  font-size: 1rem;
  border-radius: 5px;
  background-color: #fa6005;
  color: #fff;
  text-align: center;
  padding: 10px;
  width: 100%;
}

.enroll_done {
  font-size: 1rem;
  border-radius: 5px;
  background-color: #128b3d;
  color: #fff;
  text-align: center;
  padding: 10px;
  width: 100%;
  margin: 20px 0;
}

.affilate_marketing h5 {
  font-size: 2rem;
  font-weight: bold;
  color: #01348f;
  margin: 30px 0;
}
.affilate_marketing p {
  line-height: 28px;
}
.affilate_marketing h4 {
  color: #fa6005;
  margin: 30px 0;
}
.affilate_marketing a {
  background-color: #002543;
  color: #fff;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
}
.affilate_marketing h6 {
  background-color: #eb0303;
  color: #fff;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
}

.tottal_withdrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tottal_withdrow .total_price {
  font-size: 1rem;
}
.tottal_withdrow .total_price span {
  background-color: #012f7e;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline-block;
}
.tottal_withdrow .withdrow .btn {
  background-color: #0d7a33;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #0d7a33;
}
.tottal_withdrow .withdrow .btn img {
  width: 20px;
  margin-right: 5px;
}
.tottal_withdrow .withdrow .btn:hover {
  background-color: #128b3d;
}

.payment_redirect {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment_redirect .payment_redirect_card {
  background-color: rgba(13, 122, 51, 0.137254902);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.04);
  width: 70%;
  padding: 50px;
  margin: auto;
  text-align: center;
  border-radius: 5px;
}
.payment_redirect .payment_redirect_card .checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  -webkit-animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
          animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}
.payment_redirect .payment_redirect_card .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
          animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.payment_redirect .payment_redirect_card .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
          animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@-webkit-keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}
.payment_redirect .payment_redirect_card h5 {
  font-weight: bold;
  font-size: 1.6rem;
  color: #002543;
  margin-top: 20px;
}
.payment_redirect .payment_redirect_card p {
  width: 60%;
  margin: 15px auto;
}

.field_payment {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.field_payment .circle,
.field_payment .circle-border {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.field_payment .circle {
  z-index: 1;
  position: relative;
  background: white;
  transform: scale(1);
  -webkit-animation: success-anim 700ms ease;
          animation: success-anim 700ms ease;
}
.field_payment .circle-border {
  z-index: 0;
  position: absolute;
  transform: scale(1.1);
  -webkit-animation: circle-anim 400ms ease;
          animation: circle-anim 400ms ease;
  background: #f86;
}
@-webkit-keyframes success-anim {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes success-anim {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes circle-anim {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1.1);
  }
}
@keyframes circle-anim {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1.1);
  }
}
.field_payment .error::before,
.field_payment .error::after {
  content: "";
  display: block;
  height: 4px;
  background: #f86;
  position: absolute;
}
.field_payment .error::before {
  width: 40px;
  top: 48%;
  left: 16%;
  transform: rotateZ(50deg);
}
.field_payment .error::after {
  width: 40px;
  top: 48%;
  left: 16%;
  transform: rotateZ(-50deg);
}

.nomarginh {
  margin-top: 0;
}

.affila_table_info thead tr {
  background-color: #478ecc;
}
.affila_table_info thead tr th {
  color: #fff;
  border: 0;
}
.affila_table_info thead tr th:first-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.affila_table_info thead tr th:last-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

/* info_card */
.info_card {
  border: 1px solid #478ecc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}
.info_card .card_icon {
  background-color: #478ecc;
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
}
.info_card .card_icon img {
  width: 30px;
}
.info_card h4 {
  font-size: 1.1rem;
  margin: 0;
  color: #fff;
  margin-right: 5px;
}
.info_card .card_title {
  font-size: 1.4rem;
  margin-top: 15px;
}

/* Footer */
footer {
  background-size: cover;
  text-align: center;
  height: 239px;
  margin-top: 125px;
  padding-top: 50px;
}
footer img {
  max-width: 60px;
  margin: auto;
  margin-bottom: 30px;
}
footer ul {
  margin-bottom: 0;
}
footer ul li {
  display: inline-block;
}
footer ul li a {
  display: inline-block;
  margin: 4px;
  width: 39px;
  height: 36px;
  background-color: #fff;
  text-align: center;
  line-height: 36px;
  color: #01348f !important;
  transition: all 0.2s ease;
  -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  border-radius: 15px;
}
footer ul li a:hover {
  -webkit-clip-path: none !important;
          clip-path: none !important;
  border-radius: 0;
}

.modalSubPrice .modal-dialog {
  margin: auto;
}
.modalSubPrice .modal-content {
  border: 0;
}
.modalSubPrice .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
}
.modalSubPrice .modal-header .close {
  outline: 0;
  box-shadow: none;
}
.modalSubPrice .modal-title {
  font-size: 1rem;
}
.modalSubPrice .modal-footer {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.modalSubPrice .modal-footer .btn {
  background-color: #002543;
  color: #fff;
}
.modalSubPrice .modal-footer .btn:hover {
  background-color: #01348f;
}

.subPriceForm {
  display: flex;
  align-items: center;
}
.subPriceForm .btn {
  padding: 0 !important;
  width: 200px !important;
}
.subPriceForm .btn img {
  width: 20px;
  margin-left: 5px;
}
.subPriceForm .form-control {
  height: 45px;
}
.subPriceForm .form-control:focus {
  outline: 0;
  box-shadow: none;
}
.subPriceForm .btn {
  background-color: #0d7a33;
  height: 45px;
  color: #fff;
  margin-right: 5px;
}
.subPriceForm .btn:hover {
  background-color: #128b3d;
}

@media (max-width: 1024px) {
  .mynavbr .mynavbar-parent ul .navItem .navLink {
    padding: 8px;
  }
  .mynavbr .mynavbar-parent ul .navItem {
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .mynavbr .mynavbar-parent {
    flex-direction: row-reverse;
  }
  .mynavbr .links {
    display: none !important;
  }
  .mynavbr .login {
    display: none;
  }
  .sidemenue-icon {
    display: block !important;
  }
  .teste-card {
    margin-bottom: 40px;
  }
  .login-content .loginForm {
    width: 100%;
  }
  .login-content h5 {
    margin-top: 30px;
  }
  .course-details .cours-det .latest-course {
    display: none;
  }
}
@media (max-width: 524px) {
  html {
    font-size: 12px;
  }
  .overview .over-content-2 {
    margin-top: 10px;
  }
  .section-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .section-header .bnt-more .more {
    display: inline-block;
    margin-top: 20px;
  }
  .login-content h5 {
    margin-top: 0;
  }
  .login-img h5 {
    margin-top: 80px;
  }
  .my-account .tab-content {
    margin-top: 30px;
  }
  .course-details .course-info ul li {
    flex-direction: column;
    align-items: flex-start;
  }
  .course-details .cours-content .card .card-title {
    flex-direction: column;
  }
  .course-details .cours-content .card .card-title .btn {
    margin-top: 5px;
  }
  .course-details .course-info ul li .desc {
    margin-top: 5px;
  }
  .mynavbr .mynavbar-parent {
    padding: 15px;
    flex-direction: row-reverse !important;
  }
  .mynavbr .mynavbar-parent .logo {
    display: none;
  }
  .course-details .cours-det .buy-course .paymentOption {
    flex-direction: column;
  }
  .course-details .cours-det .buy-course .paymentOption .subPrice {
    margin-right: 0;
    margin-top: 10px;
  }
  .modalSubPrice .modal-dialog {
    margin: 0.5rem;
  }
  .tottal_withdrow {
    flex-direction: column;
    align-items: flex-start;
  }
  .tottal_withdrow .withdrow {
    margin-top: 15px;
  }
  .affila_table_info_parent .affila_table_info {
    min-width: 600px;
  }
}
@media (max-width: 370px) {
  .subPriceForm {
    flex-direction: column;
  }
  .subPriceForm .btn {
    margin-top: 10px;
  }
}
/* EXTENDS */
.header, .course-card .card-img, .course-card .card-instructor .card-img-instructio, .teste-card .card-img, .main-video, .breadcrumb, .my-account .course_img .card_img, .course-details .cours-det .course-img {
  -webkit-background-size: cover;
  -webkit-background-position: center;
  -moz-background-position: center;
  -moz-background-size: cover;
  background-position: center;
  background-size: cover;
}

.Mysidebar .list-sidebar .navItem .submenue .navItem::marker, .testemonial .slick-arrow.slick-prev::before, .testemonial .slick-arrow.slick-next::before, .course-details .coursr-descreption-content .nav .nav-link.active::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.contact_form {
  margin-top: 70px;
}
.contact_form .form-control {
  height: 60px;
  border: 1px solid #DED;
}
.contact_form .form-control:focus {
  outline: 0;
  box-shadow: none;
}
.contact_form textarea {
  resize: none;
  height: 150px !important;
}
.contact_form .btn_submit .btn {
  background-color: #01348f;
  color: #fff;
  border: 0;
  padding: 15px 50px;
  border: 1px solid #01348f;
}
.contact_form .btn_submit .btn:hover {
  background-color: #fff;
  color: #01348f;
}

.withdrow_modal .form-control {
  height: 60px;
  margin-top: 20px;
}
.withdrow_modal .form-control:focus {
  outline: 0;
  box-shadow: none;
}

.course_video_free .card {
  padding: 15px 15px;
  box-shadow: 6px 4px 18px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  border: 0;
}
.course_video_free .card .card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  font-size: 16px;
}
.course_video_free .card .card-title .btn {
  background-color: #179c46;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.course_video_free .card .card-title .btn i {
  margin-top: 7px;
  margin-right: 5px;
}
.course_video_free .card .card-title .btn:hover {
  background-color: #128b3d;
}/*# sourceMappingURL=style.css.map */