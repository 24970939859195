@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700&display=swap");
$MAINCOLOR: #01348f;
$SECONDCOLOR: #fa6005;
$THIRDCOLOR: #000;
$FOURTHCOLOR: #478ecc;

input,
select,
textarea {
  &:focus {
    outline: 0 !important;
  } 
}

body {
  font-family: FF;
  font-family: "Cairo", sans-serif;
  direction: rtl;
  margin: 0;
  box-sizing: border-box !important;
}

div,
a,
p,
span,
h6 h5,
h4,
h3,
h2,
h1 {
  box-sizing: border-box;
}

.clear {
  clear: both;
}

img {
  max-width: 100%;
  // width: 100%;
}

a,
button {
  text-decoration: none !important;
  outline: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/* side nav */
.openMenuActive {
  height: 100vh;
  overflow: hidden;
  // display: none;
}

/* Loading */
.loading_parent {
  // position: relative;
}
.section-loading {
  top: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  z-index: 999999;
  align-items: center;
  justify-content: center;
  background-color: $MAINCOLOR;
  transition: all 0.3s ease-in-out;
  right: 0;
  ul.list-bars {
    list-style: none;
    display: flex;
    align-items: center;
    li {
      margin: 0 7px;
      width: 8px;
      border-radius: 10px;
      background-color: #fff;
      animation: animate 0.8s infinite alternate;
    }
    li:nth-child(odd) {
      height: 80px;
      animation-delay: 0.6s;
    }
    li:nth-child(even) {
      height: 20px;
    }
  }
}

.form_page {
  opacity: 0.4;
}

.loading_none {
  opacity: 0 !important;
  visibility: hidden !important;
  // height: 0 !important;
  z-index: -1 !important;
}

@keyframes animate {
  from {
    height: 5px;
  }
  to {
    height: 70px;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  text-align: right;
  font-family: "Cairo", sans-serif !important;
}

.Toastify__toast--rtl {
  font-family: "Cairo", sans-serif !important;
}

.Toastify__toast-container
  .Toastify__toast-container--top-right
  .Toastify__toast-container--rtl {
  font-family: "Cairo", sans-serif !important;
}

.bg-sidenavOpen {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  cursor: pointer;
  display: none;
}

.Mysidebar {
  height: 100vh;
  background-color: #fff;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
  width: 230px;
  position: fixed;
  top: 0;
  right: -300px;
  overflow: auto;
  padding: 10px 20px;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  .closeSideMenue {
    position: absolute;
    left: 10px;
    width: 35px;
    height: 35px;
    background-color: transparent;
    color: $MAINCOLOR;
    border: 2px solid $MAINCOLOR;
    border-radius: 50%;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .list-sidebar {
    padding: 0;
    margin: 0;
    list-style-type: none;
    .navItem {
      margin-bottom: 10px;
      .navLink {
        font-size: 16px;
        color: $THIRDCOLOR;
        font-weight: 500;
        border-bottom: 1px solid rgb(243, 243, 243);
        display: block;
        padding: 8px 0;
        &:hover {
          color: $MAINCOLOR;
        }
      }
      .submenue {
        padding: 0;
        padding-right: 20px;
        margin: 0;
        .navItem {
          margin-bottom: 0;
          margin-top: 10px;
          &::marker {
            content: "\f104";
            color: $SECONDCOLOR;
            @extend %init-font-awesome;
          }
          &:hover {
            &::marker {
              color: $MAINCOLOR;
            }
            .navLink {
              color: $MAINCOLOR;
            }
          }
        }
        .navLink {
          font-size: 16px;
          color: #000;
        }
      }
      &:last-child{
        .navLink{
          border-bottom: 0;
        }
      }
    }
  }
  .logo {
    text-align: center;
    padding-top: 40px;
    margin-bottom: 40px;
    img {
      width: 80px;
    }
  }
  .login {
    display: flex;
    flex-direction: column;
    text-align: center;
    .navLink {
      font-size: 16px;
      background-color: $MAINCOLOR;
      color: #fff;
      border-radius: 5px;
      font-weight: 500;
      padding: 10px;
      // margin-right: 10px;
      margin-top: 10px;
      &:hover {
        background-color: #179c46;
      }
      &:first-child {
        background-color: #f1f1f1;
        color: $THIRDCOLOR;
        &:hover {
          background-color: #dfdfdf;
        }
      }
    }
  }
}

/* mynavbr */
.mynavbr {
  z-index: 99;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  // box-shadow: 1px 2px 2px rgba(#000000, 10%);
  // border-radius: 50px;
  position: absolute;
  top: 0;
  width: 100%;
  .mynavbar-parent {
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    margin-top: 10px;
    padding: 5px 15px;
    border-radius: 10px;
    .logo {
      width: 40px;
    }
    ul {
      display: flex;
      margin-bottom: 0;
      margin-top: 0;
      list-style-type: none;
      padding: 0;
      align-items: center;
      justify-content: center;
      box-sizing: border-box !important;
      .navItem {
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        box-sizing: border-box !important;
        .navLink {
          font-size: 16px;
          color: $THIRDCOLOR;
          font-weight: 600;
          padding: 10px;
          border-radius: 5px;
          box-sizing: border-box !important;
          display: flex;
          align-items: center;
          i {
            margin-top: 4px;
            margin-left: 5px;
            color: $THIRDCOLOR;
            transition: all 0.3s ease-in-out;
          }
        }
        &:hover {
          a {
            color: $MAINCOLOR;
            i {
              color: $MAINCOLOR;
            }
          }
          &::after {
            width: calc(100% - 50%);
          }
          .submenue {
            opacity: 1 !important;
            visibility: visible !important;
            transform: translateY(3px) !important;
          }
        }
      }
    }
    .login {
      display: flex;
      .navLink {
        font-size: 16px;
        background-color: $MAINCOLOR;
        color: #fff;
        border-radius: 5px;
        font-weight: 500;
        padding: 10px;
        margin-right: 10px;
        i {
          margin-left: 5px;
          margin-top: 3px;
        }
        &:hover {
          background-color: #012f7e;
        }
        &:first-child {
          background-color: #f1f1f1;
          color: $THIRDCOLOR;
          &:hover {
            background-color: #dfdfdf;
          }
        }
      }
      .dropdown {
        button {
          font-size: 16px;
          padding: 10px;
          margin-right: 10px;
          background-color: #012f7e;
          border-color: #012f7e;
          &:focus {
            outline: 0 !important;
            box-shadow: none;
          }
        }

        .btn_logout {
          background-color: transparent;
          border-color: transparent;
          margin: 0;
          // padding: 0;
          color: #eb0303;
          width: 100%;
          padding: 0;
          text-align: right;
          margin-right: 10px;
          i{
            margin-left: 10px;
          }
        }
        a {
          color: #000;
          padding: 7px 10px !important;
          display: block;
          font-size: 0.9rem;
          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
          i {
            font-size: 0.9rem;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .sidemenue-icon {
    display: none;
    i {
      font-size: 25px;
      color: $MAINCOLOR;
    }
  }
}

/* header */
.header {
  @extend %img-bg-style;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  .header_img_video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    video, img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .bg-after {
    position: absolute;
    z-index: 1;
    bottom: -50px;
    left: 0;
    right: 0;
    /* width: 100%; */
    height: 100%;
    background-position: center;
    /* transform: rotate(180deg); */
    background-size: cover;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.26);
  }
  .header-content {
    h5 {
      font-size: 50px;
      color: #fff;
      font-weight: 800;
      position: relative;
      z-index: 9;
      margin: 0;
    }
    p {
      font-size: 20px;
      color: #fff;
      position: relative;
      z-index: 9;
      margin: 0;
    }
  }
}

.about_us_page{
  h5{
    font-size: 2rem;
    font-weight: bold;
    margin: 30px 0;
  }
  p{
    line-height: 30px;

  }
}

.overview {
  padding-top: 97px;
  .container {
    position: relative;
  }
  .overview-img {
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 200px;
    filter: grayscale(1);
    opacity: 0.2;
  }
  .over-content-1 {
    h5 {
      font-size: 16px;
      color: $SECONDCOLOR;
      margin: 0;
      margin-bottom: 0;
      font-weight: 600;
    }
    p {
      color: #002543;
      font-size: 27px;
      font-weight: bold;
    }
  }
  .over-content-2 {
    margin-top: 20px;
    p {
      font-size: 18px;
      line-height: 25px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      // width: 524px;
      line-height: 30px;
      max-width: 100%;
    }
  }
}

/* statics */
.statics {
  margin-top: 90px;
}
/* state-card */
.state-card {
  border-radius: 15px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: all 0.5s ease;
  text-align: center;
  padding: 30px 5px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  .card-img {
    border-radius: 24px;
    padding: 15px;
    width: 85px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .card-img-1 {
    background-image: linear-gradient(135deg, $MAINCOLOR 10%, $FOURTHCOLOR);
  }
  .card-img-2 {
    background-image: linear-gradient(135deg, $SECONDCOLOR 10%, $SECONDCOLOR);
  }
  .card-img-3 {
    background-image: linear-gradient(135deg, $FOURTHCOLOR 10%, $MAINCOLOR);
  }
  .card-img-4 {
    background-image: linear-gradient(135deg, $SECONDCOLOR 10%, $SECONDCOLOR);
  }
  .card-number {
    font-size: 40px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
  }
  .card-title {
    font-size: 16px;
    font-weight: 800;
    margin: 0;
    transition: all 0.3s ease-in-out;
    margin-top: 10px;
  }
  .card-text {
    margin: 0;
    font-size: 15px;
    margin-top: 9px;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    transform: translateY(-8px);
    background-color: $MAINCOLOR;
    .card-number,
    .card-text,
    .card-title {
      color: #fff;
    }
  }
}

/* cources */
.section-header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h5 {
    font-size: 40px;
    font-weight: bold;
    color: $MAINCOLOR;
    margin: 0;
  }
  p {
    color: #444;
    font-size: 14px;
    margin: 0;
  }
  .bnt-more {
    text-align: center;
    // margin-top: 40px;
    .more {
      padding: 10px 25px;
      text-align: center;
      color: #444;
      font-size: 17px;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid rgb(214, 214, 214);
      &:hover {
        background-color: #ddd;
        color: $MAINCOLOR;
        border-color: #ddd;
      }
    }
  }
}
.cources {
  margin-top: 90px;
}
.course-card {
  background-color: #fff;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  margin-bottom: 20px;
  .card-img {
    height: 300px;
    @extend %img-bg-style;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    .img_parent {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
  .card-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .card-instructor {
    display: flex;
    align-items: center;
    .card-img-instructio {
      width: 26px;
      height: 37px;
      border-radius: 50%;
      @extend %img-bg-style;
      margin-left: 5px;
      background-size: contain !important;
      background-repeat: no-repeat;
    }
    span {
      font-size: 15px;
      font-weight: 500;
      color: $MAINCOLOR;
    }
  }
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 25px;
    color: #000;
  }
  .card-rate {
    display: flex;
    align-items: center;
    i {
      // margin-top: 5px;
      margin-left: 5px;
      color: #ffc600;
    }
    span {
      width: fit-content;
      background-color: $FOURTHCOLOR;
      color: #fff;
      display: inline-block;
      border-radius: 5px;
      padding: 3px 5px;
    }
  }
  .card-duration {
    font-size: 15px;
    color: $MAINCOLOR;
    // margin-top: 15px;
    display: flex;
    align-items: center;
    i {
      margin-top: 5px;
      margin-left: 5px;
    }
  }
  .card-price {
    font-size: 40px;
    font-weight: bold;
    color: #179c46;
    margin-top: 10px;
    span {
      font-size: 15px;
    }
  }
  .card-footer {
    padding: 0;
    border-top: 0;
    .visit-cource {
      background-color: $MAINCOLOR;
      color: #fff;
      font-size: 17px;
      text-align: center;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 5px;
      border-radius: 5px;
      margin-top: 10px;
    }
  }
  &:hover {
    transform: translateY(-10px);
  }
}

/* testemonial */
.testemonial {
  margin-top: 90px;
  background-color: #ebebeb;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
  .section-header {
    margin-bottom: 10px;
  }
  .slick-slide {
    padding: 10px;
  }
  .slick-list {
    padding-top: 30px;
  }
  .teste-bf {
    position: absolute;
    bottom: -40px;
    width: 100%;
  }
  .slick-dots {
    display: flex !important;
    justify-content: center;
    margin: 0;
    list-style-type: none;
    li {
      margin: 0;
    }
    button {
      display: block;
      width: 1rem;
      height: 1rem;
      padding: 0;
      border: none;
      border-radius: 100%;
      border: 2px solid $SECONDCOLOR;
      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: $SECONDCOLOR;
    }
  }
  .slick-arrow {
    display: block;
    width: 40px;
    height: 40px;
    background-color: $SECONDCOLOR;
    border-radius: 5px;
    &.slick-prev {
      z-index: 9;
      left: 0;
      top: 100%;
      margin-top: 20px;
      &::before {
        content: "\f104";
        @extend %init-font-awesome;
        opacity: 1;
      }
    }
    &.slick-next {
      right: 0;
      top: 100%;
      margin-top: 20px;
      z-index: 9;
      &::before {
        content: "\f105";
        @extend %init-font-awesome;
        opacity: 1;
      }
    }
    &.slick-active {
      &::before {
      }
    }
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.35;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    background-color: #e05606;
  }
}
/* teste card */
.teste-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  padding-bottom: 15px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 10px solid $SECONDCOLOR;
  .card-img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 4px solid #fff;
    @extend %img-bg-style;
    background-size: 100% !important;
    background-repeat: no-repeat;
    position: relative;
    // top: -30px;
    // left: -30px;
    margin-top: -60px;
    .img_parent {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  .card-name,
  .card-text {
    margin: 0;
    text-align: center;
  }
  .card-name {
    font-size: 17px;
    margin-top: 10px;
    font-weight: 600;
  }
  .card-date {
    color: #666;
    font-size: 13px;
    display: inline-block;
  }
  .card-text {
    font-size: 15px;
    margin-top: 10px;
  }
  &:hover {
    border-bottom-color: $MAINCOLOR;
  }
}

/*  */
.main-video-parent {
  margin-top: 120px;
}
.main-video {
  @extend %img-bg-style;
  height: 400px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &::after {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    bottom: 0;
    top: 0;
    content: "";
    position: absolute;
    border-radius: 10px;
  }
  .btn-play {
    border-radius: 50%;
    border: 0;
    width: 130px;
    height: 130px;
    font-size: 50px;
    color: $SECONDCOLOR;
    position: relative;
    z-index: 6;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* our-services */
.our-services {
  margin-top: 90px;
}
/* serv-card */
.serv-card {
  box-shadow: 6px 4px 18px rgba(0, 0, 0, 0.08);
  border: 1px solid #eee;
  background-color: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  .serv-number {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    top: 20px;
    left: 0;
    background-color: $MAINCOLOR;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    padding: 5px 20px;
  }
  .card-title {
    margin-top: 40px;
    font-size: 25px;
    font-weight: bold;
  }
  .card-text {
    line-height: 30px;
    color: #333;
    font-size: 15px;
    margin-top: 15px;
  }
  &:hover {
    // border-color: transparent;
    box-shadow: none;
  }
}

/* contact */
/* contact */
.contact {
  margin-top: 100px;
  .section-contact {
    margin-bottom: 61px;
    h6 {
      color: #ddd;
      font-size: 18px;
    }
    h5 {
      color: $THIRDCOLOR;
      font-size: 30px;
    }
  }
  .cont {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    h5,
    p {
      margin: 0;
    }
    .cont-img {
      margin-left: 10px;
    }
    h5 {
      font-size: 20px;
      color: $THIRDCOLOR;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    p, a {
      font-size: 15px;
      color: #7f8384;
      margin: 0;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .contact-img {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* login-img */
.login-page {
  .row {
    margin: 0;
    .col-md-6 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.error_handle {
  color: red;
  font-size: 0.9rem;
}
.login-img {
  height: 100vh;
  // background-image: url("../images/pattern.png");
  background-color: $FOURTHCOLOR;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  h5 {
    font-size: 3em;
    color: #fff;
    font-weight: bold;
  }
  p {
    color: #fff;
    // margin: auto;
    margin-top: 10px;
    line-height: 30px;
    width: 80%;
    max-width: 100%;
  }
  a {
    border: 2px solid #fff;
    color: #fff;
    width: 50%;
    padding: 10px;
    &:hover {
      background-color: #fff;
      color: $MAINCOLOR;
    }
  }
}

.login-content {
  // padding-top: 100px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h5 {
    color: $MAINCOLOR;
    font-weight: bold;
    font-size: 2.5em;
    text-align: center;
  }
  .loginForm {
    width: 70%;
    margin: auto;
    margin-top: 30px;
  }

  .form-control {
    height: 55px;
    background-color: #eee;
    border-color: #eee;
    &:focus {
      box-shadow: none;
      outline: 0;
      border: 1px solid $MAINCOLOR;
    }
  }

  .btnSignin {
    color: #fff;
    border-radius: 0;
    width: 200px;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    background-color: $MAINCOLOR;
    padding: 10px 10px;
    border-radius: 5px;
    margin-top: 15px;
  }
  .social {
    text-align: center;
    margin-top: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    a {
      width: 35px;
      height: 35px;
      border: 1px solid #999;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      // display: inline-block;
      flex-direction: row;
      margin: 5px;
      i {
        color: #999;
      }
    }
  }
}

.rigister-page {
  .loginForm {
    width: 90% !important;
  }
}

/* Breadcrumb */
.breadcrumb {
  @extend %img-bg-style;
  height: 500px;
  display: block;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.65);
  }
  h5 {
    display: block !important;
    font-size: 39px;
    margin-bottom: 18px;
    font-weight: bold;
    color: #fff;
    position: relative;
    z-index: 9;
  }
}

/* my account */
.my-account {
  margin-top: 40px;
  margin-bottom: 50px;
  .log-out {
    margin-top: 10px;
    .btn {
      background-color: rgb(235, 3, 3);
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
      padding: 10px 10px;
      i {
        // margin-top: 5px;
        margin-left: 5px;
        transform: rotate(180deg);
      }
      &:hover {
        background-color: rgb(211, 3, 3);
      }
    }
  }
  .nav {
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 5px;
  }
  .nav-link {
    padding: 20px 10px;
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    i {
      margin-top: 5px;
      margin-left: 5px;
    }
    &.active {
      background-color: $MAINCOLOR;
      &:hover {
        background-color: $MAINCOLOR;
      }
    }
    &:hover {
      background-color: #eee;
    }
  }
  .nav-item {
    &:last-child {
      .nav-link {
        border-bottom: 0;
      }
    }
  }
  .tabHed {
    background-color: #f1f1f1;
    padding: 20px 10px;
    border-radius: 5px;
    h5 {
      margin: 0;
      color: #000;
      font-size: 18px;
    }
  }
  .tabContent {
    margin-top: 40px;
    form{
      overflow: hidden;
    }
    .form-group {
      .form-control {
        height: 55px;
        border-radius: 5px;
        border-color: #eee;
        font-size: 16px;
        color: #000;
        background-color: #fff;
        &:focus {
          outline: 0;
          box-shadow: none;
          border-color: $MAINCOLOR;
        }
      }
      input:disabled {
        background-color: #eee;
      }
    }
    .course-card .card-img {
      height: 200px;
    }
  }
  .btn-submit {
    button {
      width: 100%;
      background-color: #179c46;
      color: #fff;
      padding: 15px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      &:hover {
        background-color: #13853b;
      }
    }
  }


  // course img
  .course_img{
    text-align: center;
    // cursor: pointer;
    .courseImg{
      // cursor: pointer;
    
    }
    .upload_title{
      background-color: #002543;
      color: #fff;
      padding: 15px 40px;
      display: inline-block;
    }
    label, input{
      cursor: pointer;
    }
    .card_img{
      cursor: pointer;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      @extend %img-bg-style;
      position: relative;
      margin: auto;
      .img_parent{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
    input[type="file"]{
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
    }
  }
}

/* course-details */
.course-details {
  margin-bottom: 50px;
  .cours-det {
    .course-img {
      margin-top: -60px;
      @extend %img-bg-style;
      height: 400px;
      border-radius: 5px;
      position: relative;
      .img_parent {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }
    .intro {
      margin-top: 10px;
      .btn {
        padding: 15px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $MAINCOLOR;
        flex-direction: row-reverse;
        i {
          margin-top: 5px;
          color: #fff;
          font-size: 18px;
        }
        span {
          color: #fff;
          font-size: 18px;
        }
        img {
          width: 25px;
        }
        &:hover {
          background-color: #002543;
        }
      }
    }
    .buy-course {
      .btn {
        background-color: #179c46;
        &:hover {
          background-color: #0d7a33;
        }
        img {
          width: 25px;
        }
      }
      .paymentOption {
        display: flex;
        align-items: center;
        // justify-content: space-between;
      }
      .seprate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        .line {
          width: 100%;
          height: 1px;
          background-color: rgb(201, 201, 201);
        }
        .world {
          padding: 0 5px;
          font-weight: bold;
        }
      }
      .subPrice {
        // background-color: #0d7a33;
        // color: #fff;
        // border-radius: 5px;
        // border: 0;
        // padding: 15px 10px !important;
        // width: 100%;
        // text-align: center !important;
        // justify-content: flex-end;
        margin: 0;
        margin-right: 10px;
        img {
          width: 25px;
        }
      }
    }
    .latest-course {
      margin-top: 40px;
      .latest-head {
        background-color: #f1f1f1;
        padding: 20px 10px;
        border-radius: 5px;
        margin-bottom: 20px;
        h5 {
          margin: 0;
          color: #000;
          font-size: 18px;
        }
      }
      .course-card {
        .card-img {
          height: 200px;
        }
      }
    }
  }
  .course-info {
    background-color: #fff;
    box-shadow: 6px 4px 18px rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;
    .course-info-det {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 2px;
      color: $SECONDCOLOR;
      margin-bottom: 20px;
    }
    ul {
      display: flex;
      flex-direction: column;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        .title {
          display: flex;
          align-items: center;
          color: #444;
          i {
            margin-top: 5px;
            margin-left: 5px;
          }
        }
        .desc {
          font-weight: 600;
          font-size: 17px;
          display: flex;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-left: 5px;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .coursr-descreption-content {
    .nav {
      background-color: #01348f;
      align-items: center;
      /* justify-content: space-between; */
      justify-content: space-evenly;
      border-radius: 10px;
      margin-top: 30px;
      .nav-link {
        background-color: transparent;
        color: #fff;
        font-size: 15px;
        border-radius: 0;
        border: 0;
        border-bottom: 0 solid transparent;
        &.active {
          // border-color: $MAINCOLOR;
          // background-color: $MAINCOLOR;
          color: #fff;
          position: relative;
          border: 0;
          &::after {
            content: "\f0d7";
            position: absolute;
            @extend %init-font-awesome;
            bottom: -25px;
            left: 50%;
            transform: translate(-50%, 0);
            color: $MAINCOLOR;
            font-size: 30px;
          }
        }
      }
    }
    .description-title {
      font-size: 19px;
      margin: 0;
      margin-bottom: 20px;
      font-weight: bold;
      margin-top: 30px;
    }
    .description-desc {
      font-size: 14px;
      color: #444;
      line-height: 30px;
      ul{
        list-style-type: none;
        padding-right: 0;
      }
    }
  }
  .cours-content {
    .card {
      padding: 15px 15px;
      box-shadow: 6px 4px 18px rgba(0, 0, 0, 0.08);
      margin-bottom: 20px;
      border: 0;
      .card-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        font-size: 16px;
        .btn {
          background-color: #179c46;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          i {
            margin-top: 7px;
            margin-right: 5px;
          }
          &:hover {
            background-color: #128b3d;
          }
        }
      }
    }
  }

  /* comments */
  .comments {
    margin-top: 70px;
    .comment-head {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        background-color: #eee;
        width: 100%;
        height: 1px;
        display: inline-block;
        margin-top: 5px;
      }
      h5 {
        margin: 0;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .comment-card {
    border: 1px solid #eee;
    background-color: #fff;
    margin-top: 10px;
    padding: 20px;
    position: relative;
    border-radius: 5px;
    .card-date {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .card-title {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }
    .card-text {
      color: #444;
      margin: 0;
      font-size: 14px;
      margin-top: 10px;
      line-height: 25px;
    }
    .name_img{
      display: flex;
      align-items: center;
    }
    .card_img{
      width: 50px;
      height: 50px;
      position: relative;
      border-radius: 50%;
      margin-left: 10px;
      .img_parent{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img{
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
  }
  .add-comment {
    margin-top: 50px;
    .comment-head {
      justify-content: space-between !important;
      margin-bottom: 20px;
      span {
        background-color: #eee;
        width: 200px;
      }
    }
    .form-control {
      height: 55px;
      &:focus {
        outline-style: 0;
        box-shadow: none;
      }
    }
    textarea {
      height: 150px !important;
      resize: none;
    }
    .btn {
      background-color: $MAINCOLOR;
      color: #fff;
      font-size: 17px;
    }
  }
  .noLogin_title {
    // color: red;
    font-size: 1rem;
    text-align: center;
    margin-top: 15px;
    border: 1px solid #eee;
    padding: 15px;
  }
}

/* courses_page */
.courses_page {
  .section-header {
    padding: 20px 0;
  }
}

/* certificate_checker */
.certificate_checker {
  padding: 100px 0;
  .login-content {
    height: auto !important;
  }
}

/* certificate_details_page */
.certificate_details_page {
  // min-height: 100vh;
  padding: 100px 0;
  .head_page {
    color: $MAINCOLOR;
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
  }
  .info_cards {
    margin: 50px 0;
  }
}

.enroll_error {
  font-size: 1rem;
  border-radius: 5px;
  background-color: $SECONDCOLOR;
  color: #fff;
  text-align: center;
  padding: 10px;
  width: 100%;
}

.enroll_done {
  font-size: 1rem;
  border-radius: 5px;
  background-color: #128b3d;
  color: #fff;
  text-align: center;
  padding: 10px;
  width: 100%;
  margin: 20px 0;
}

.affilate_marketing {
  h5 {
    font-size: 2rem;
    font-weight: bold;
    color: $MAINCOLOR;
    margin: 30px 0;
  }
  p {
    line-height: 28px;
  }
  h4 {
    color: $SECONDCOLOR;
    margin: 30px 0;
  }

  a {
    background-color: #002543;
    color: #fff;
    display: block;
    width: fit-content;
    padding: 10px;
    border-radius: 5px;
  }
  h6 {
    background-color: #eb0303;
    color: #fff;
    text-align: center;
    width: fit-content;
    padding: 10px;
    border-radius: 5px;
  }
}

.tottal_withdrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .total_price {
    font-size: 1rem;
    span {
      background-color: #012f7e;
      color: #fff;
      padding: 10px 20px;
      border-radius: 5px;
      margin-right: 10px;
      display: inline-block;
    }
  }
  .withdrow {
    .btn {
      background-color: #0d7a33;
      color: #fff;
      padding: 10px 20px;
      border: 1px solid #0d7a33;
      img {
        width: 20px;
        margin-right: 5px;
      }
      &:hover {
        background-color: #128b3d;
        // color: #128b3d;
      }
    }
  }
}

.payment_redirect {
  min-height: 100vh;
  display: flex;
  align-items: center;

  justify-content: center;
  .payment_redirect_card {
    background-color: #0d7a3323;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.04);
    width: 70%;
    padding: 50px;
    margin: auto;
    text-align: center;
    border-radius: 5px;
    .checkmark {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: #4bb71b;
      stroke-miterlimit: 10;
      box-shadow: inset 0px 0px 0px #4bb71b;
      animation: fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
      position: relative;
      top: 5px;
      right: 5px;
      margin: 0 auto;
    }
    .checkmark__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: #4bb71b;
      fill: #fff;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    .checkmark__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }

    @keyframes stroke {
      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes scale {
      0%,
      100% {
        transform: none;
      }

      50% {
        transform: scale3d(1.1, 1.1, 1);
      }
    }

    @keyframes fill {
      100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
      }
    }
    h5 {
      font-weight: bold;
      font-size: 1.6rem;
      color: #002543;
      margin-top: 20px;
    }
    p {
      width: 60%;
      margin: 15px auto;
    }
  }
}

.field_payment {
  $color-red: #f86;
  $circle-size: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .circle,
  .circle-border {
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
  }

  .circle {
    z-index: 1;
    position: relative;
    background: white;
    transform: scale(1);
    animation: success-anim 700ms ease;
  }

  .circle-border {
    z-index: 0;
    position: absolute;
    transform: scale(1.1);
    animation: circle-anim 400ms ease;
    background: $color-red;
  }

  @keyframes success-anim {
    0% {
      transform: scale(0);
    }
    30% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes circle-anim {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1.1);
    }
  }

  .error::before,
  .error::after {
    content: "";
    display: block;
    height: 4px;
    background: $color-red;
    position: absolute;
  }

  .error::before {
    width: 40px;
    top: 48%;
    left: 16%;
    transform: rotateZ(50deg);
  }

  .error::after {
    width: 40px;
    top: 48%;
    left: 16%;
    transform: rotateZ(-50deg);
  }
}

.nomarginh {
  margin-top: 0;
}

.affila_table_info {
  thead {
    tr {
      background-color: $FOURTHCOLOR;
      // border-radius: 5px;
      th {
        color: #fff;
        border: 0;
        &:first-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        &:last-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
      }
    }
  }
}

/* info_card */
.info_card {
  border: 1px solid $FOURTHCOLOR;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  .card_icon {
    background-color: $FOURTHCOLOR;
    // width: 40px;
    // height: 40px;
    border-radius: 5px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    img {
      width: 30px;
    }
  }
  h4 {
    font-size: 1.1rem;
    margin: 0;
    color: #fff;
    margin-right: 5px;
    // margin-top: 15px;
  }
  .card_title {
    font-size: 1.4rem;
    margin-top: 15px;
  }
}

/* Footer */
footer {
  background-size: cover;
  // background-position: center;
  text-align: center;
  height: 239px;
  margin-top: 125px;
  padding-top: 50px;
  img {
    max-width: 60px;
    // max-height: 66px;
    margin: auto;
    margin-bottom: 30px;
  }
  ul {
    margin-bottom: 0;
    li {
      display: inline-block;
      a {
        display: inline-block;
        margin: 4px;
        width: 39px;
        height: 36px;
        background-color: #fff;
        text-align: center;
        line-height: 36px;
        color: $MAINCOLOR !important;
        transition: all 0.2s ease;
        clip-path: polygon(
          25% 0%,
          75% 0%,
          100% 50%,
          75% 100%,
          25% 100%,
          0% 50%
        );
        border-radius: 15px;
        &:hover {
          clip-path: none !important;
          border-radius: 0;
        }
      }
    }
  }
}

.modalSubPrice {
  .modal-dialog {
    margin: auto;
  }
  .modal-content {
    border: 0;
  }
  .modal-header {
    padding-top: 10px;
    padding-bottom: 10px;
    .close {
      outline: 0;
      box-shadow: none;
    }
  }

  .modal-title {
    font-size: 1rem;
  }
  .modal-footer {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .btn {
      background-color: #002543;
      color: #fff;
      // margin-right: auto;
      &:hover {
        background-color: $MAINCOLOR;
      }
    }
  }
}

.subPriceForm {
  display: flex;
  align-items: center;
  .btn {
    padding: 0 !important;
    width: 200px !important;
    img {
      width: 20px;
      margin-left: 5px;
    }
  }
  .form-control {
    height: 45px;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  .btn {
    background-color: #0d7a33;
    height: 45px;
    color: #fff;
    margin-right: 5px;
    &:hover {
      background-color: #128b3d;
    }
  }
}

@media(max-width:1024px) {
  .mynavbr .mynavbar-parent ul .navItem .navLink{
    padding: 8px;
  }
  .mynavbr .mynavbar-parent ul .navItem{
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .mynavbr {
    .mynavbar-parent {
      flex-direction: row-reverse;
    }
    .links {
      display: none !important;
    }
    .login {
      display: none;
    }
  }
  .sidemenue-icon {
    display: block !important;
  }
  .teste-card {
    margin-bottom: 40px;
  }
  .login-content .loginForm {
    width: 100%;
  }
  .login-content h5 {
    margin-top: 30px;
  }
  .course-details .cours-det .latest-course {
    display: none;
  }
}

@media (max-width: 524px) {
  html {
    font-size: 12px;
  }
  .overview .over-content-2 {
    margin-top: 10px;
  }
  .section-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .section-header .bnt-more .more {
    display: inline-block;
    margin-top: 20px;
  }
  .login-content h5 {
    margin-top: 0;
  }
  .login-img h5 {
    margin-top: 80px;
  }
  .my-account {
    .tab-content {
      margin-top: 30px;
    }
  }
  .course-details .course-info ul li {
    flex-direction: column;
    align-items: flex-start;
  }
  .course-details .cours-content .card .card-title {
    flex-direction: column;
  }
  .course-details .cours-content .card .card-title .btn {
    margin-top: 5px;
  }
  .course-details .course-info ul li .desc {
    margin-top: 5px;
  }
  
  .mynavbr {
    .mynavbar-parent {
      padding: 15px;
      flex-direction: row-reverse !important;
      .logo {
        display: none;
      }
    }
  }
  .course-details .cours-det .buy-course .paymentOption {
    flex-direction: column;
    .subPrice {
      margin-right: 0;
      margin-top: 10px;
    }
  }
  .modalSubPrice .modal-dialog {
    margin: 0.5rem;
  }
  .tottal_withdrow{
    flex-direction: column;
    align-items: flex-start;
    .withdrow{
      margin-top: 15px;
    }
  }
  .affila_table_info_parent{
    .affila_table_info{
      min-width: 600px;
    }
  }
}

@media (max-width: 370px) {
  .subPriceForm {
    flex-direction: column;
    .btn {
      margin-top: 10px;
    }
  }
}

/* EXTENDS */

%inilize-ui-icons {
  font-family: uicons-regular-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%img-bg-style {
  -webkit-background-size: cover;
  -webkit-background-position: center;
  -moz-background-position: center;
  -moz-background-size: cover;
  background-position: center;
  background-size: cover;
}

%img-bg-after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

%link-transition {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

%spcfies-number-of-line {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

%init-font-awesome {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

%transition {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

%line-after-secton-header {
  content: "";
  position: absolute;
  background-color: #25a1a5;
  border-radius: 10px;
  width: 41px;
  height: 5px;
  bottom: 0;
  left: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.contact_form{
  margin-top: 70px;
  .form-control{
    height: 60px;
    border: 1px solid #DED;
    &:focus{
      outline: 0;
      box-shadow: none;
    }
  }
  textarea{
    resize: none;
    height: 150px !important;
  }
  .btn_submit{
    .btn{
      background-color: $MAINCOLOR;
      color: #fff;
      border: 0;
      padding: 15px 50px;
      border: 1px solid $MAINCOLOR;
      &:hover{
        background-color: #fff;
        color: $MAINCOLOR;
      }
    }
  }
}

.withdrow_modal{
  .form-control{
    height: 60px;
    margin-top: 20px;
    &:focus{
      outline: 0;
      box-shadow: none;
    }
  }
}

.course_video_free{
  .card {
    padding: 15px 15px;
    box-shadow: 6px 4px 18px rgba(0, 0, 0, 0.08);
    margin-bottom: 20px;
    border: 0;
    .card-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      font-size: 16px;
      .btn {
        background-color: #179c46;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        i {
          margin-top: 7px;
          margin-right: 5px;
        }
        &:hover {
          background-color: #128b3d;
        }
      }
    }
  }
}